(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/shared-constants/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/shared-constants/assets/javascripts/constants.js');
"use strict";


const LOCAL_STORAGE_VERSION = 1;
const constants = {
  FILTER_UPDATE: 'COOP_PLAY/FILTER_UPDATE',
  FILTER_QUERY_NAME: 'COOP_PLAY/FILTER_QUERY_NAME',
  FILTER_TOGGLE_PRODUCT: 'COOP_PLAY/FILTER_TOGGLE_PRODUCT',
  FILTER_SET_PRODUCT: 'COOP_PLAY/FILTER_SET_PRODUCT',
  FILTER_FRACTION_PRICE: 'COOP_PLAY/FILTER_FRACTION_PRICE',
  FILTER_FRACTION_COUNT: 'COOP_PLAY/FILTER_FRACTION_COUNT',
  FILTER_SYSTEM_PRICE: 'COOP_PLAY/FILTER_SYSTEM_PRICE',
  CLEAR_FILTER: 'COOP_PLAY/CLEAR_FILTER',
  LOAD_QUERYSTRING: 'COOP_PLAY/LOAD_QUERYSTRING',
  LOAD_LOCALSTORAGE: 'COOP_PLAY/LOAD_LOCAL_STORAGE',
  PAGE_SIZE: 2,
  QS_FRACTION_DATA: 'fractionData',
  QS_NAME: 'search',
  QS_PRODUCT: 'products',
  QS_SHARE_PRICE_MIN: 'sharePriceMin',
  QS_SHARE_PRICE_MAX: 'sharePriceMax',
  QS_SHARE_COUNT_MIN: 'shareCountMin',
  QS_SHARE_COUNT_MAX: 'shareCountMax',
  QS_SYSTEM_PRICE_MIN: 'systemPriceMin',
  QS_SYSTEM_PRICE_MAX: 'systemPriceMax',
  fractionPriceMaxDisplayValue: 1000,
  fractionPriceMinDisplayValue: 10,
  fractionAmountMaxDisplayValue: 50,
  fractionAmountMinDisplayValue: 2,
  systemSizeMinDisplayValue: 20,
  systemSizeMaxDisplayValue: 42000,
  COOP_PLAY_FETCH_INIT: 'COOP_PLAY/COOP_PLAY_FETCH_INIT',
  COOP_PLAY_FETCH_FAILURE: 'COOP_PLAY/COOP_PLAY_FETCH_FAILURE',
  COOP_PLAY_FETCH_SUCCESS: 'COOP_PLAY/COOP_PLAY_FETCH_SUCCESS',
  COOP_PLAY_FETCH_NEXT_INIT: 'COOP_PLAY/COOP_PLAY_FETCH_NEXT_INIT',
  COOP_PLAY_FETCH_NEXT_SUCCESS: 'COOP_PLAY/COOP_PLAY_FETCH_NEXT_SUCCESS',
  COOP_PLAY_FETCH_NEXT_FAILURE: 'COOP_PLAY/COOP_PLAY_FETCH_NEXT_FAILURE',
  FRACTION_PAYMENT_INIT: 'FRACTION_PAYMENT/FRACTION_PAYMENT_INIT',
  FRACTION_PAYMENT_SUCCESS: 'FRACTION_PAYMENT/FRACTION_PAYMENT_SUCCESS',
  FRACTION_PAYMENT_FAILURE: 'FRACTION_PAYMENT/FRACTION_PAYMENT_FAILURE',
  LOCAL_STORAGE_VERSION,
  LOCAL_STORAGE_NAME: "v".concat(LOCAL_STORAGE_VERSION),
  LOCAL_STORAGE_DOMAIN: 'svs.marketplaceShared.CoOpPlay',
  DATA_EXPIRATION: 1000 * 60 * 60 * 24 * 8 
};
setGlobal('svs.components.marketplaceShared.sharedConstants.constants', constants);

 })(window);